html{
    height:100%;
  }
  

  .wrapper{
    
    width:100%;
    height:150px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-style: dashed;
    border-color: #cfc2ff;
    min-width: 280px;
  
    .file-upload{
  
      height:100%;
      width:100%;
      border-radius: 1px;
      position:relative;
      display:flex;
      justify-content:center;
      align-items: center;  
      border:4px solid #FFFFFF;
      overflow:hidden;
      background-size: 100% 200%;
      transition: all 1s;
      color: #5C499F;
      font-size:80px;
  
      input[type='file']{
  
        height:400px;
        width:400px;
        position:absolute;
        top:0;
        left:0;
        opacity:0;
        cursor:pointer;
  
      }
  
      &:hover{
  
        background-position: 0 -100%;
        color:#5C499F
  
      }
  
  
    }
  }