
%arrows {
 
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;

    &:focus,
  
    &.control-arrow {
        &:before {
            margin: 0 5px;
            display: inline-block;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            content: '';
        }
    }

    &.control-disabled {
      
        cursor: inherit;
        display: none;
    }

    &.control-prev {
        left: 0;
        &:before {
            border-right: 8px solid #fff;
        }
    }

    &.control-next {
        right: 0;
        &:before {
            border-left: 8px solid #5C499F !important;
        }
    }
}

.carousel-root {
    outline: none;
}

.carousel {
   
    .control-arrow {
        outline: 0;
        border: 0;
        background: none;
        top: 50%;
        margin-top: -13px;
        font-size: 18px;
    }

    .control-dots {
        position: absolute;
        bottom: 0;
        margin: 10px 0;
        padding: 0;
        text-align: center;
        width: 100%;
        z-index: 1;

       
        .dot {
           
            box-shadow: 1px 1px 2px rgba(rgb(156, 156, 156), 0.9);
            background: #5C499F !important;
            border-radius: 50%;
            width: 12px !important;
            height: 12px !important;
            cursor: pointer;
            display: inline-block;
            margin: 0 8px;

         
        }
    }

    
}



