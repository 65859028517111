body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.rdt_TableCol:first-child {
	border-radius: 15px 0 0 0 !important;
}

.rdt_TableCol:last-child {
	border-radius: 0 15px 0 0 !important;
}

.dropdown-secondary {
	border-color: #44719D;
	background-color: #fff;
	color: #44719D;
}

.btn-opc-base {
    background-color: #f0f0f093;
	border-radius: 50px;
	border: none;
	height: 38px;
	width: 38px;
	cursor: pointer;
	justify-content: center;	
	display: flex;
}


.icon-edit {
	color: #f7c221;
	font-size: 19px;
}

.icon-success {
	color: #4bb543;
	font-size: 19px;
}

.icon-wrong {
	color: #fb6d51;
	font-size: 19px;
}

.icon-info {
	color: #009FE3;
	font-size: 19px;
}

.mapboxgl-ctrl-icon .mapboxgl-ctrl-zoom-in {
	background-image: url("/public/icons/in.svg") !important;
	width: 40px;
	fill: #44719D;
	}

	
	